<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <validation-observer ref="simpleRules">

        <b-card class="border-light">

          <h1 class="text-primary my-1">Charge Group Settings</h1>

          <b-row>


            <b-col cols="12" lg="3" class="mb-3 mt-2">
              <span class="mb-2">
                Charge Group Type
              </span>
              <validation-provider name="Charge Group Type" #default="{ errors }" rules="required">
                <v-select :taggable="true" v-model="selectedGroup.type" :value="selectedGroup.type"
                  :options="chargeGroupTypes" :clearable="false" placeholder="Select Charge Group">
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col cols="12" lg="3" class="mb-3 mt-2">
              <span class="mb-2">
                Charge Group Title
              </span>
              <validation-provider #default="{ errors }" name="Charge Group" rules="required">

                <!-- <v-select
              v-model="selectedGroup"
                            :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'model' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :value="vehicleData.model"
              :state="errors.length > 0 ? false : null"
              :options="modelOptionsVal"
              :reduce="(val) => val.id"
              label="value"
              :clearable="true"
              @input="trimClear()"
              :disabled="!vehicleData.make > 0 || oldCardDisabled || vehicleData.editable == 'no'"
            >
            </v-select> -->


                <v-select :loading="dropdownLoading"
                  :create-option="(temp) => ({ id: 0, type: selectedGroup.type, stock: selectedGroup.stock, title: temp, categories: [] })"
                  :value="selectedGroup" :taggable="true" @option:created="createNewDropdownValue"
                  :options="chargeGroups" v-model="selectedGroup" @input="groupSelected"
                  :state="errors.length > 0 ? false : null" label="title" :clearable="false">
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>



            <b-col cols="12" lg="2" class="mb-3 mt-2">
              <span class="mb-2">
                Cost Applied to Vehicle
              </span>

              <b-form-checkbox v-model="selectedGroup.stock" class="ml-5 mt-50" title="Cost Applied to Vehicle"
                name="Cost Applied to Vehicle" switch>
              </b-form-checkbox>

            </b-col>
          </b-row>

          <b-row>


            <b-col>

              <h2 class="text-primary my-1"> All Categories</h2>

              <b-row>
                <b-col cols="6">
                  <b-form-group class="mb-40">
                    <b-input-group>
                      <b-form-input v-model.lazy="newCategory" type="text" placeholder="Add Category"></b-form-input>
                      <b-input-group-append>
                        <b-button @click="addItem()" variant="success">Add +</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="mb-40">
                    <b-input-group>
                      <b-form-input id="filter-input" v-model="filter" type="search"
                        placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button @click="filter = ''" variant="primary">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table :items="displayCategories" :small="true" responsive style="padding-bottom:30px"
                class="position-relative " head-variant="dark" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered" :fields="column" striped hover :current-page="currentPage" :per-page="perPage">

                <template #cell(name)="data">
                  <span v-if="edit != item.id">{{ data.item.title ? data.item.title : 'N/A' }}</span>
                  <b-form-input v-else v-model="item[key]" />
                </template>



                <template v-slot:cell(actions)="data">
                  <!-- <b-button size="sm" @click="onEdit(data.item.id, data.item.value)" class="mr-1" variant="primary">
                  {{ edit === data.item.id ? 'Save' : 'Edit' }}
                </b-button>
                <b-button size="sm" @click="removeItem($event, data.item.id)" class="mr-1" variant="danger">
                  Del
                </b-button> -->

                  <b-button v-if="selectedGroup.title" size="sm" @click="selectCategory(data.item)" class="mr-1"
                    variant="success">
                    Select
                  </b-button>



                </template>
              </b-table>
              <b-row>


                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  style="padding-right: 5px;">
                  <b-pagination first-number last-number :total-rows="totalRows" :per-page="perPage"
                    v-model="currentPage" class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </b-col>

            <b-col>


              <b-row>

                <h2 class="text-primary my-1">Selected Categories</h2>

                <b-col cols="6">

                </b-col>

                <b-col cols="6">
                  <b-form-group class="mb-40">
                    <b-input-group>
                      <b-form-input id="filter-input" v-model="filterSelected" type="search"
                        placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button @click="filterSelected = ''" variant="primary">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table :items="selectedGroup.categories" :small="true" responsive style="padding-bottom:30px"
                class="position-relative " head-variant="dark" :filter="filterSelected"
                :filter-included-fields="filterOn" @filtered="onFilteredSelected" :fields="column" striped hover
                :current-page="currentPageSelected" :per-page="perPage">
                <template #cell(name)="data">
                  <span>{{ data.item.title ? data.item.title : 'N/A' }}</span>
                </template>


                <template v-slot:cell(actions)="data">


                  <b-button size="sm" @click="removeCategory(data.item)" class="mr-1" variant="danger">
                    Remove
                  </b-button>


                </template>
              </b-table>
              <b-row>


                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  style="padding-right: 5px;">
                  <b-pagination first-number last-number :total-rows="totalRowsSelected" :per-page="perPageSelected"
                    v-model="currentPageSelected" class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                    next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>

            </b-col>





          </b-row>

          <b-row>




            <b-col class="d-flex flex-row-reverse">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="primary" class="mb-5"
                @click="saveGroup()">
                Save
              </b-button>
            </b-col>


          </b-row>
        </b-card>

    </validation-observer>
  </b-overlay>
</template>

<script>
import { BCard, BBadge, BCardTitle, BCardText, BAvatar, BButton, BRow, BCol } from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "@/views/settings/settingsStoreModule.js";
import SettingsCard from "@core/components/statistics-cards/SettingsCard.vue";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import axiosIns from "@/libs/axios";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from 'vuedraggable';
import vSelect from 'vue-select';




export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BCardActions,
    SettingsCard, ValidationProvider, ValidationObserver,
    draggable,
    vSelect
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      chargeGroups: [],
      allCategories: [],
      displayCategories: [],
      selectedGroup: {
        'id': null,
        'type': 'Purchase Order',
        'title': null,
        'categories': [],
        'stock': null,
      },

      selectedCategories: [],
      dropdownLoading: false,

      loading: true,
      dailyPrice: null,
      untilDays: null,
      afterDailyPercentage: null,




      edit: null,
      newCategory: '',
      filter: null,

      filterSelected: null,
      filterOn: [],
      perPage: 10,
      perPageSelected: 10,
      items: [],
      currentPage: 1,
      currentPageSelected: 1,

      pageOptions: [5, 10, 15, 20],

      chargeGroupTypes: ['Purchase Order', 'Sale Order'],

      column: [
        { key: 'title', sortable: true, label: 'Name' },
        { key: 'actions', label: 'Actions', thClass: 'text-right', tdClass: 'text-right pr-0' },
      ],

    };
  },

  methods: {
    groupSelected() {


      var x = []
      this.selectedGroup = this.chargeGroups.find((x) => x.title.toUpperCase() == this.selectedGroup.title.toUpperCase())
      console.log(this.selectedGroup)

      this.selectedGroup.categories.forEach(element => {
        x.push(element.value)

      });

      this.displayCategories = this.allCategories.filter(n => !x.includes(n.value))

    },

    createNewDropdownValue(val) {
      this.dropdownLoading = true;
      console.log(val)

      if (this.chargeGroups.find((x) => x.title.toUpperCase() == val.title.toUpperCase()) == undefined) {

        val.title = val.title.toUpperCase();

        store.dispatch('settings/createChargeGroup', val).then((response) => {
          if (response.status == 201) {
            this.chargeGroups.unshift(response.data);
            this.selectedGroup = response.data;
            this.dropdownLoading = false;
          }
        });
      } else {
        this.dropdownLoading = false;
      }

    },


    getItems() {


      store.dispatch("settings/getChargeGroups").then((res) => {
        res.data.groups.forEach(element => {
          var cats = []
          element.categories.forEach(cat => {
            cats.push({
              'value': cat.id,
              'title': cat.categoryName
            })
          });
          this.chargeGroups.push({
            'id': element.id,
            'title': element.title,
            'type': element.type,
            'stock' : element.stock ? true : false,
            'categories': cats
          })

        });

        res.data.categories.forEach(element => {
          this.allCategories.push({
            'value': element.id,
            'title': element.categoryName
          })

        });

        this.displayCategories = this.allCategories
        this.loading = false;


      })
        .catch((error) => { });


    },




    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onFilteredSelected(filteredItems) {
      this.totalRowsSelected = filteredItems.length;
      this.currentPageSelected = 1;
    },




    addItem() {
      if (this.newCategory == '') {
        return;
      }
      axiosIns
        .post('saveCategory', {
          categoryName: this.newCategory.toUpperCase(),
        })
        .then((res) => {
          if (res.status == 201) {
            this.newCategory = '';
            this.allCategories.unshift({
              'value': res.data.id,
              'title': res.data.categoryName
            })

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectCategory(val) {
      this.selectedGroup.categories.push(val)
      var x = []

      this.selectedGroup.categories.forEach(element => {
        x.push(element.value)

      });

      this.displayCategories = this.allCategories.filter(n => !x.includes(n.value))

    },


    removeCategory(val) {

      this.selectedGroup.categories = this.selectedGroup.categories.filter(n => n.value != val.value)
      var x = []

      this.selectedGroup.categories.forEach(element => {
        x.push(element.value)

      });

      this.displayCategories = this.allCategories.filter(n => !x.includes(n.value))

    },


    saveGroup() {

      var catIds = []
      this.selectedGroup.categories.forEach(element => {
        catIds.push(element.value)

      });

      this.selectedGroup.categoryIds = catIds;
      store
        .dispatch('settings/updateChargeGroup', this.selectedGroup)
        .then((response) => {

          this.$swal({
            icon: "success",
            title: "The changes have been saved.",
            text: "",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });


        })
        .catch((error) => {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong",
              text: "Please try again or report an issue to support",
              icon: "ThumbsDownIcon",
              variant: "danger",
            },
          });


        });


    },





  },

  computed: {

    totalRows() {
      return this.displayCategories.length;
    },

    totalRowsSelected() {
      return this.selectedGroup.categories.length;
    },


    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: this.isDefaultOption,
        ghostClass: 'ghost',
      };
    },






  },
  created() {
    this.getItems();
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return {
      // Filter
    };
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.edit-column {
  border-radius: 10%;
  font-weight: bold;
  overflow: hidden;
  max-width: 30%;
  height: 25%;
  color: white;
  background-color: #68ea8b !important;
  text-align: center;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 3px;
  outline-color: white;
  border: white;
  font-size: small;
}

.list-button {
  margin-right: 5px;
}

.hidden {
  display: none !important;
}

#first-row {
  display: flex;
  height: 300px;
  width: 100%;
  overflow: auto;
}

#grid-container {
  grid-auto-flow: row;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;
  height: 400px;
  align-content: start;
  gap: 2px;
}

#grid-item {
  display: block;
  border: 1px solid rgb(233, 232, 232);
  height: 75px;
}

.list-group-item {
  transition: all 1s;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
